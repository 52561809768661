.container {
    width: 100%;
    min-height: 80vh;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0rem 4rem;

    @media (min-width:320px) {
        flex-direction: column;
        margin: 50px 0;
        padding: 0 1.25rem;
    }

    .cars_card {
        @media (min-width:320px) {
            width: 100%;
            min-height: 80vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            padding: 0.55rem;
        }

        @media (min-width:768px) {
            width: 80%;
        }

        @media (min-width:1024px) {
            width: 80%;
            min-height: 400px;
            align-items: center;
            justify-content: center;
            transition: 0.3s;
            gap: 20px;
            padding: 1rem;
            border-radius: 6px;
        }

        .card_image {
            @media (min-width:320px) {
                width: 100%;
                height: 100%;
                border-bottom: 2px solid black;
                border-bottom: 2px solid slateblue;
            }

            @media (min-width:1024px) {
                width: 800px;
                height: 400px;
                margin-right: 50px;
            }

            img {
                @media (min-width:320px) {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }

                @media (min-width:1024px) {
                    object-fit: contain;
                }
            }

        }

        .card_body {
            @media (min-width:320px) {
                width: 100%;
            }

            @media (min-width:1024px) {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

            }

            h1 {
                margin: 8px 0;
                color: #333;

                @media (min-width:1024px) {
                    font-size: 40px;
                }
            }

            h2 {
                margin: 0;
                color: #333;
            }

            .list_price {
                @media (min-width:320px) {
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    background: none;
                    color: #3c3c3c;
                    margin: 5px 0;

                }

                @media (min-width:1024px) {
                    height: 80px;
                    margin-left: 16px;
                }

                p {
                    margin-right: 15px;
                    font-weight: 600;
                }

                span {
                    font-size: 40px;
                    font-weight: 700;
                    margin-right: 10px;
                }

            }

            .card_list {
                @media (min-width:320px) {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    gap: 20px;
                    padding: 0;
                }

                @media (min-width:1024px) {
                    margin: 4px 0;
                    padding: 12px;
                    padding-top: 12px;
                }

                li {
                    @media (min-width:320px) {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #868686;
                        color: #555;
                        padding: 10px 4px;
                        font-weight: 600;
                        &:nth-child(odd) {
                            border-bottom: 1px solid #ccc;
                        }
                    }
                }

            }

            .buttons {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn {
                    width: 100%;
                    padding: 14px 0;
                    background: #3B4FF9;
                    color: white;
                    border: 1px solid #3B4FF9;
                    outline: none;
                    font-weight: 600;
                    font-size: 16px;
                    cursor: pointer;
                    transition: 0.2s;

                    &:hover {
                        background: none;
                        color: #3b4ff9;
                        color: slateblue;
                    }
                }
            }
        }
    }

    .buttons2 {
        @media (min-width:320px) {
            width: 100%;
            text-align: center;
            border: 1px solid #ccc;
            margin-top: 20px;
        }

        @media (min-width:768px) {
            width: 50%;
        }

        @media (min-width:1024px) {
            display: none;
        }

        .btn2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 4px 20px;
            font-size: 34px;
            background-color: transparent;
            color: black;
            text-decoration: none;
        }
    }
}

.loader_block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    .loader {
        width: 300px;
        object-fit: cover;
        clip-path: circle();
    }
}