.none {
  display: none;
}

.userDrop {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  right: 10px;
  top: 80px;
  width: 200px;
  height: 250px;
  background: #3646D4;
  border-radius: 10px;
  padding: 15px;
  z-index: 100;
  .username {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .person {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50px;
      background-color: slateblue;
      color: white;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;

      }
    }

    h4 {
      color: white;
      margin: 0;
      font-size: 13px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background: white;
      position: absolute;
      bottom: -10px;
      left: 0;

    }
  }

  .list {
    height: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 25px;

    li {
      list-style: none;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
}