.support {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  background: #3B4FF9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 25px;
  bottom: 25px;
  animation: pulse 1s ease-in infinite;
  transform: scale(1);
  cursor: pointer;

  li {
    list-style: none;
    font-size: 25px;
    color: white;
  }
}


@keyframes pulse {
  0% {
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 #5d61aa66;
  }

  70% {
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}