.statistics {
  @media (min-width:320px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 80px;
    padding: 20px 0;
  }
  @media (min-width:425px) {
    justify-content: space-between;
  }
  @media (min-width:1024px) {
    width: 80%;
    justify-content: center;
    gap: 40px;
  }

  .stat_block {
    @media (min-width:320px) {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      color: #0e1eab;
      font-weight: 700;

    }
    @media (min-width:1024px) {
      margin: 0;
      padding: 0 4px;
    }
  }

  p {
    margin: 20px 0;
  }
}