.savedCars{
  display: flex;
  flex-direction: column;
  align-items: center ;
  .title{
    text-align: center;
  }
}

.savesIsHavent{
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button{
    border: 1px solid #3646D4;
    background: none;
    color: #3646D4;
    transition: .5s;
    &:hover{
      background: #3646D4;
      color: white;
    }
  }
}