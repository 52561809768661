.anchor {
  width: 50px;
  height: 50px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 85px;
  right: 25px;
  border-radius: 5px;
  opacity: 1;
  transition: .5s;
  cursor: pointer;
  background: #3B4FF9;
  border: 1px solid white;

  li {
    display: flex;
    list-style: none;
    font-size: 25px;
    color: white;
  }
}

.anchorNone {
  opacity: 0;
  transition: .5s;
}