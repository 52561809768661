.support{
  padding: 15px;
  background: #5C5CD6;
  border: 5px solid white;
  border-radius: 15px;
  z-index: 100;
  position: fixed;
  @media (min-width: 320px) {
    width: 100%;
    height: 90vh;
    bottom: 0;
    right: 0; 
  }
  @media (min-width: 768px) {
    width: 350px;
    height: 500px ;
    bottom: 25px  ;
    right: 100px; 
  }
  .support_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    position: relative;
    &::before{
      content: '';
      width: 100%;
      height: 1px;
      background: white;
      position: absolute;
      bottom: -5px;
      left: 0;
    }
    
    .left{
      display: flex;
      align-items: center;
      img{
        width: 25px;
        height: 25px;
      }
      p{
        margin: 0;
        margin-left: 15px;
        color: white;
      }
    }
    
    .right{
      color: white;
      li{
        list-style: none;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .chat_container{
    position: relative;
    background: white;
    height: 80%;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: right;
    position: relative;
    overflow-y: scroll;
    
    &::-webkit-scrollbar{
      display: none;
    }
  
    &::-webkit-scrollbar-thumb{
      display: none ;
    }
    .messageContainer{
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .myMessage{
        @media (min-width: 320px) {
          width: 90%;
        }
        @media (min-width: 768px) {
          width: 250px;
        }
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        background: #5C5CD6;
        border-radius: 10px 10px 0 10px;
        margin-bottom: 15px;
        padding: 15px;
        .mess{
          margin-right: 50px;
          word-break: break-all;
          i{
            color: white;
            margin: 0;
            font-size: 13px;
          }
          p{
            color: white;
            margin: 0;
            font-size: 13px;
            margin-top: 5px;
          }
        }
        .time{
          position: absolute;
          bottom: 0;
          p{
            color: white;
            font-size: 13px;
          }
        }
      }
    }
    .answerContainer{
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: flex-start;
      bottom: 0;
      left: 10px;
      .answer{
        @media (min-width: 320px) {
          width: 90%;
        }
        @media (min-width: 768px) {
          width: 250px;
        }
        padding: 15px;
        display: flex;
        align-items: flex-end;
        background: #F2F2F2;
        text-align: left;
        border-radius: 10px 10px 10px 0;
        margin-top: 15px;
        position: relative;
        left: 0;
        top: 75%;
        bottom: 15px;
        .mess{
          margin-left: 50px;
          word-break: break-all;
          i{
            color: #333;
            margin: 0;
            font-size: 13px;
          }
          p{
            color: #333;
            margin: 0;
            font-size: 13px;
            margin-top: 5px;
          }
        }
        .time{
          position: absolute;
          bottom: 0;
          left: 15px;
          p{
            color: #333;
            font-size: 13px;
          }
        }
      }
    }
    .pauseMessage{
      width: 90%;
      background: #3B4FF9;
      color: white;
      border: 0;
      border-radius: 5px;
      position: absolute;
      bottom: 10px;
    }
  }
  .send_container{
    width: 100%;
    height: 40px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 10px 10px;
    input{
      width: 100%;
      padding: 10px ;
      border: 0;
      border-radius: 5px;
      outline: none;
      margin-right: 10px;
    }
    li{
      list-style: none;
      background: white;
      border-radius: 5px;
      color: #3B4FF9;
      padding: 8px 20px;
      cursor: pointer;
    }
  }
}