.userInfo{
  display: none;
  @media (min-width: 1440px) {
    display: flex;
  }
  align-items: center;
  cursor: pointer;
  .person{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: slateblue;
    color: white;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    p{
      margin: 0;
    }
  }
  li{
    list-style: none;
    margin-left: 10px;
  }
  .default{
    transform: rotateX(0);
    transition: .5s;
  }
  .rotated{
    transform: rotateX(180deg);
    transition: .5s;
  }
}
