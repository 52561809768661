 .notifications{
  padding: 50px;
  .message{
    width: 100%;
    height: 100px;
    background: aliceblue;
    border: 1px solid #d6e8f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    p{
      margin: 5px 0;
      color: #555;
    }
  }
}