.card_container {
    @media (min-width:320px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 !important;
        margin-top: 20px;
        padding: 4rem 0.55rem;
        gap: 20px;
    }

    @media (min-width:768px) {
        padding: 20px 3rem;
    }

    .cars_card {
        position: relative;
        @media (min-width:320px) {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: white;
            padding: 0;
            box-shadow: 0 0 5px rgba(100, 100, 100, 0.9);
            margin: 10px 0;
            border-radius: 12px;
        }

        @media (min-width:768px) {
            margin: 20px 0px;
        }

        @media (min-width:1024px) {
            width: 400px;
            height: auto;
            box-shadow: none;
            transition: 0.3s;
            margin: 20px 2.5px;
            border: 1px solid #d6e8f9;

            &:hover {
                box-shadow: none;
            }
        }

        @media (min-width:1300px) {
            width: 450px;
            height: 250px;
        }


        .card_body {
            @media (min-width:320px) {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0.55rem;
                display: flex;
                justify-content: space-between;
                border: 0;
            }

            @media (min-width:1024px) {
                display: flex;
                width: 100%;
                height: 200px;
            }

            .card_img {
                @media (min-width:320px) {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                @media (min-width:768px) {
                    height: 200px;
                }

                @media (min-width:1024px) {
                    height: auto;
                }

                img {
                    @media (min-width:320px) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .card_title {
                @media (min-width:320px) {
                    display: flex;
                    width: 50%;
                    gap: 10px;
                    flex-direction: column;
                    align-items: flex-end;
                    text-align: right;
                }

                @media (min-width:768px) {
                    justify-content: center;
                }

                h1,
                h2,
                h3,
                h4 {
                    margin: 0;
                    color: #777;
                }

                h1 {
                    @media (min-width:320px) {
                        font-size: 1.2rem;
                        color: #333;
                        margin: 0;
                    }
                }
            }

            .favorites_btn1 {
                width: 35px;
                height: 35px;
                margin: 0;
                border: 1px solid black;
                cursor: pointer;
                border-radius: 4px;
            }

        }

        .card_footer {
            @media (min-width:320px) {
                width: 100%;
                height: 90%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 10px;
                gap: 8px;
                list-style: none;
            }

            @media (min-width:425px) {
                width: 100%;
                margin-top: 10px;
                gap: 12px;
            }

            @media (min-width:1024px) {
                height: 12%;

            }

            .card_footer_btn {
                @media (min-width:320px) {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    border: none;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 600;
                    transition: 0.2s ease-in-out;
                    padding: 12px 20px;
                    background: #3B4FF9;
                    color: white;
                    transition: .5s;
                    border-radius: 0px 30px 2px 8px;
                }

                &:nth-child(2) {
                    border-radius: 30px 0 8px 2px;
                    border-left: 1px solid #d6e8f9;
                }

                &:hover {
                    width: 100%;
                    color: white;
                    background-color: #3B4FF9;
                }
            }

        }
    }
}