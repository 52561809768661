@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


*{
  font-family: montserrat;
  box-sizing: border-box;

}

body{
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  &::-webkit-scrollbar{
    display: none;
  }

  &::-webkit-scrollbar-thumb{
    display: none ;
  }
}


.loader_block{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: black;
  .loader{
      width:300px;
      object-fit: cover;
      clip-path: circle();
      display: none;
  }
}
