.container {
    @media (min-width:320px) {
        padding: 1rem;
    }

    @media (min-width:1024px) {
        padding: 2rem 3rem;
    }

    .iframe {
        @media (min-width:320px) {
            width: 100%;
            border-radius: 12px;
            border: 1px solid #ccc;
        }
        @media (min-width:1024px){
            height: 450px;
        }

    }

    h1 {
        @media (min-width:320px) {
            font-size: 30px;
            font-weight: 600;
            color: #333;
        }
    }

    p {
        color: #777;

        @media (min-width:1024px) {
            font-size: 20px;
        }
    }
}