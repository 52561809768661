.container {
    @media (min-width:320px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 1rem;
        gap: 40px;
    }

    .card {
        @media (min-width:320px) {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0 1rem;
            border-radius: 10px;
            transition: 0.3s;
            border: 1px solid #ccc;
        }
        @media (min-width:1024px) {
            width: 80%;
            padding: 0 3rem;
        }
        &:hover{
            box-shadow: 0 6px 4px #0000001a, 0 12px 10px #0000001a;
            transform: translateY(-12%);
        }
        .card_title {
            @media (min-width:320px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #3B4FF9;
            }
            @media (min-width:1024px) {
                font-size: 20px;
            }
            .card_num {
                @media (min-width:320px) {
                    width: 38px;
                    height: 38px;
                    position: absolute;
                    top: -6.5%;
                    right: 2.5%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    background-color: #3B4FF9;
                    color: white;
                    font-weight: 700;
                }
                @media (min-width:768px) {
                    width: 40px;
                    height: 40px;
                }
                @media (min-width:1024px){
                    width: 55px;
                    height: 55px;
                    font-size: 25px;
                    top: -15%;
                }
            }
        }

        .card_desc {
            @media (min-width:320px) {
                font-weight: 500;
                color: #777;
            }
            @media (min-width:1024px) {
                font-size: 18px;
            }
            .desc2{
                margin: 0;
            }
        }
    }
}
.title {
    @media (min-width:320px) {
        border-bottom: 1px solid #ccc;
        padding: 0 1rem;
        margin: 20px 0;
        margin-top: 40px;
       
    }
    @media (min-width:1024px) {
        width: 100%;
        display: flex;
        padding: 0 4rem;
        margin-top: 0;
    }
    h2{
        @media (min-width:320px) {
            font-weight: 600;
            color: #333;
        }
        @media (min-width:1024px){
            font-size: 30px;
        }
    }
}