.filter{
  .filtration{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @media (min-width: 768px) {
      width: 90%;
      padding: 0 5%;
      align-items: center;
      flex-direction: row;
    }
  }
  .container {
    width: 90%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0rem 2rem;

    @media (min-width:320px) {
        width: 90%;
        overflow-x: scroll;
    }

    @media (min-width: 1024px) {
        overflow: hidden;
    }
    


    .btn {
        @media (min-width: 320px) {
          width: 100px;
        }
        width: 50px;
        height: 50px;
        background: #3B4FF9;
        border: 1px solid #3B4FF9;
        border-radius: 5px;
        color: white;
        font-size: 20px;
        &:hover {
            background: none;
            color: #3B4FF9;
        }

        @media (min-width:768px) {
            display: block;
            cursor: pointer;
            text-decoration: none;
            transition: 0.3s ease-in-out;
            margin-top: 20px;
        }
      }

      select {
          @media (min-width:320px) {
              width: 100%;
              height: 40px;
              border: 1px solid black;
              outline: none;
              margin-top: 20px;

          }

          @media (min-width:768px) {
              display: none;
          }
      }
  } 
  .sort{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    span{
      color: #5C5CD6;
      text-decoration: underline;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}