.container {
    @media (min-width:320px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 4rem 1rem;
        gap: 20px;
    }
    @media (min-width:1024px) {
        flex-direction: row;
        justify-content: space-around;
        padding: 4rem 4rem;
    }

    .right {
        @media (min-width:320px) {
            width: 100%;
        }

        @media (min-width:1024px) {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 1rem;
        }

        li {
            @media (min-width:320px) {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                padding: 14px 4px;
                background-color: #3B4FF9;
                color: white;
                border-radius: 8px;
                list-style: none;
                cursor: pointer;
            }
            @media (min-width:768px) {
                width: 150px;
            }
        }

        .content {
            @media (min-width:320px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;
            }

            h1 {
                @media (min-width:320px) {
                    font-size: 28px;
                }
                @media (min-width:1024px) {
                    font-size: 40px;
                }
                color: #333;
                margin: 0;
            }

            p {
                margin: 0;
                color: #777;
            }
        }
    }

    .left {
        @media (min-width:320px) {
            width: 100%;
        }

        @media (min-width:1024px) {
            width: 70%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}