.advantages{
  width: 90%;
  padding: 0 5%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column; 
  .heading{
    background: #4CEC96;
    padding: 10px 20px;
    border-radius: 50px;
    color: white;
    font-weight: 500;
    margin: 25px 0;
  } 
  .cards{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;
    .card{
      width: 250px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 50px;
      .img{
        width: 150px;
        height: 150px;
        background: #3B4FF9;
        box-shadow: 0 0 10px #3B4FF9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      p{
        margin-top: 35px;
      }

      .have{
        text-decoration: line-through;
      }

    }
  }
}