.container {
  @media (min-width: 1024px) {
    display: flex;
    padding: 2rem 3rem;
  }

  .card {
    @media (min-width: 320px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 1rem;
    }

    .content {
      @media (min-width: 320px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: aliceblue;
        border: 1px solid lightblue;
        border-radius: 12px;
      }

      @media (min-width: 1024px) {
        justify-content: center;
        padding: 1rem 2rem;
      }

      .top {
        @media (min-width: 320px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
        }

        @media (min-width: 1024px) {
          flex-direction: row;
        }

        .image {
          @media (min-width: 320px) {
            width: 110px;
            height: 110px;
            display: flex;
            justify-content: center;
            align-content: flex-end;
          }

          @media (min-width: 1024px) {
            width: 120px;
            height: 120px;
            background-color: #dbeefe;
            border-radius: 50%;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          @media (min-width: 320px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          @media (min-width: 1024px) {
            align-items: flex-start;
          }

          p {
            @media (min-width: 320px) {
              text-align: center;
              margin: 2.5px 0;
            }

            @media (min-width: 1024px) {
              text-align: left;
              margin: 0;
            }
          }
        }
      }

      .bottom {
        @media (min-width: 320px) {
          display: flex;
          width: 100%;
          padding: 1rem 0rem;
        }

        @media (min-width: 1024px) {
          padding: 1rem 4rem;
        }

        .list {
          @media (min-width: 320px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            list-style: none;
            gap: 10px;
          }

          @media (min-width: 1024px) {
            margin: 0;
            padding: 0 4rem;
          }

          a {
            width: 100%;
            display: flex;
            gap: 10px;
            text-decoration: none;
            color: #777;

            &:nth-child(2) {
              span {
                background-color: #fee4e4;
                color: #da351b;
              }
            }

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 50px;
              height: 50px;
              background-color: #d4fef7;
              border-radius: 50%;
              color: #1bdab5;
            }
          }
        }
      }
    }
  }
}
