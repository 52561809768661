form {
    width: 320px;
    height: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    background: none;
    border: 2px solid black;
    color: #333;

    div {
        input {
            width: 250px;
            height: 40px;
            outline: none;
            padding-left: 15px;
            margin-bottom: 15px;
        }

        button {
            width: 250px;
            height: 35px;
            background: black;
            color: white;
            margin-top: 0;
            margin-bottom: 25px;
            border: 0;
            cursor: pointer;
        }
    }

    p {
        display: flex;
        align-items: center;
        list-style: none;
        color: black;

        span {
            width: 95px;
            height: 1px;
            background: black;
            margin: 0 15px;
        }
    }
    .havent {
        margin: 15px 0;

        a {
            color: #3646D4;
            margin-left: 15px;
        }
    }
}