.container {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;

  .profile {
    @media (min-width:320px) {
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 25px;
      background-color: aliceblue;
      border: 1px solid #d7d7d7;
      border-radius: 6px;
    }

    @media (min-width:400px) {
      width: 400px;
      height: 400px;
    }

    .profile_info {
      @media (min-width:320px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .profile_img,
      .profile_text {
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;

        p {
          font-size: 30px;
          font-weight: bold;
          color: white;
        }

        input{
          display: none;
        }

        label{
          padding: 0;
          margin: 0;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &::before{
            content: 'change';
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            background: rgba($color: black, $alpha: 0.5);
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            opacity: 0;
            transition: .3s;
          }
          &:hover:before{
            content: 'change';
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            background: rgba($color: black, $alpha: 0.5);
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            opacity: 1;
          }
        }
      }

      .list {
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
        padding: 0;
        border-bottom: 1px solid #ccc;

        li {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          color: #5C5CD6;
          gap: 10px;
        }
      }
    }

    .saved {
      @media (min-width:320px) {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #5C5CD6;
        padding: 1rem;
        text-decoration: none;
      }

      @media (min-width:400px) {
        justify-content: center;
      }

      span {
        @media (min-width:320px) {
          display: flex;
          align-items: center;
          gap: 10px;
          color: white;
        }

        @media (min-width:400px) {
          margin-left: 80px;
        }
      }

    }

    .back {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 1rem;
      margin-top: 10px;
      background-color: #5C5CD6;
      color: white;
      font-size: 20px;
    }

    .btn {
      @media (min-width:320px) {
        width: 100%;
        padding: 1rem;
        background-color: #5C5CD6;
        color: white;
        border: none;
        margin-top: 10px;
        font-size: 15px;
      }

      span {
        @media (min-width:320px) {
          display: flex;
          align-items: center;
          gap: 10px;
          color: white;
        }
        p{
          display: flex;
          align-items: center;
          font-size: 17px;
        }
      }

    }
  }
}