.card_container {
    @media (min-width:320px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 !important;
        margin-top: 20px;
        padding: 0.55rem;
    }

    @media (min-width:768px) {
        padding: 20px 3rem;
    }

    @media (min-width:1024px) {
        padding: 0rem 4rem;
    }

    .cars_card {
        position: relative;
        .favorites_btn{
            width: 35px;
            height: 35px;
            list-style: none;
            border: 1px solid black;
            cursor: pointer;
            position: absolute;
            right: 25px;
            top: 25px;
            margin: 0;
        }
        @media (min-width:320px) {
            width: 302px;
            height: 320px;
            display: flex;
            flex-direction: column;
            background-color: white;
            padding: 0.55rem;
            box-shadow: 0 0 5px rgba(100, 100, 100, 0.9);
            margin: 10px 0;
        }

        @media (min-width:425px) {
            width: 380px;
            height: 350px;
        }

        @media (min-width:768px) {
            width: 450px;
            margin: 10px 10px;
        }

        @media (min-width:1024px) {
            width: 300px;
            height: 416px;
            justify-content: space-between;
            box-shadow: none;
            transition: 0.3s;
            margin: 20px 2.5px;

            &:hover {
                box-shadow: 0 0 5px rgba(100, 100, 100, 0.9);

            }
        }

        .card_image {
            @media (min-width:320px) {
                width: 100%;
                height: 144px;
            }

            @media (min-width:425px) {
                width: 100%;
                height: 170px;
            }

            @media (min-width:1024px) {
                height: 200px;
            }

            img {
                @media (min-width:320px) {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }

        .card_body {
            @media (min-width:320px) {
                border-bottom: 2px solid #3B4FF9;
                padding: 12px 0;
                margin-top: 12px;
            }

            @media (min-width:1024px) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 30%;
            }

            h2 {
                @media (min-width:320px) {
                    font-size: 18px;
                    font-weight: 600;
                    margin: 8px 0;
                    color: black;

                }
            }

            .card_price {
                @media (min-width:320px) {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                span {
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: line-through;
                    color: black;
                }

                h4 {
                    margin: 0;
                    color: black;
                }

                p {
                    margin: 4px 0;
                    font-size: 20px;
                    font-weight: 600;
                    color: black;

                    span {
                        text-decoration: none;
                        font-size: 14px;
                    }
                }
            }

        }

        .card_footer {
            @media (min-width:320px) {
                width: 100%;
                height: 90%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                gap: 5.2px;
                list-style: none;
            }

            @media (min-width:425px) {
                width: 100%;
                height: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                gap: 5.2px;
            }

            @media (min-width:1024px) {
                height: 12%;

            }

            .card_footer_btn {
                @media (min-width:320px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    border: none;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 600;
                    transition: 0.2s ease-in-out;
                    padding: 12px 20px;
                    color: white;
                    background: #3B4FF9;
                    border: 1px solid #3B4FF9;
                    transition: .5s;
                }
               
                &:hover {
                    color: #3B4FF9;
                    background: none;
                }

            }
            .favorites_btn{
                width: 50px;
                list-style: none;
                border: 1px solid black;
                cursor: pointer;
                margin: 0;
            }
        }
    }
}